import { render, staticRenderFns } from "./FinishedOrder.vue?vue&type=template&id=6637f3cd"
import script from "./FinishedOrder.vue?vue&type=script&lang=js"
export * from "./FinishedOrder.vue?vue&type=script&lang=js"
import style0 from "./FinishedOrder.vue?vue&type=style&index=0&id=6637f3cd&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports