<template>
    <div>
        <div class="wr">
            <div class="center">
                <img src="../assets/imgs/uploads/Group 465.png" alt="" style="width: 210px;height: 175px;">
            </div>
            <div class="text">
                <div class="center">Thank you!</div>
                <div class="center">Your order has been sent</div>
            </div>
            <div class="center">We will comfirm and contact you by email as soon as possible.</div>
            <router-link to="/personalcenter/Orderlist" class="bk">&lt; View My Orders</router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.wr {
    width: 462px;
    margin: 132px auto 250px;
}

.center {
    display: flex;
    justify-content: center;
}

.text {
    margin-top: 60px;
    font-family: 'Jomolhari';
    font-size: 36px;
}
.bk{
    margin: 40px auto 0;
    width: 296px;
    height: 47px;
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    background-color: #CE9F6A;
    border-radius: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>